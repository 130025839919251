/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Noto+Serif+Lao:wght@700&family=Open+Sans:ital,wght@0,300;0,400;1,300;1,400&family=Roboto:ital,wght@0,100;0,400;0,700;1,100;1,300;1,500;1,900&family=Rubik+Iso&display=swap');
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  /* background: #F5F5F5; */
  /* scroll-behavior: smooth; */
  overflow-x:hidden ;
}
body {
  font-family: "Poppins", sans-serif;
  background-color: #f0f1f3;
  overflow-x:hidden ;

}
.background {
  background-color: #d1d1d1;
  /* background: #F5F5F5; */
}
/* .slick-slider {
  overflow: hidden;
}

.slick-slide>div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
} */
.MoonLoad {

  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  font-size: 18px;
  color: #0d263b;
}
.container {
  max-width: 90%;
  margin: auto;
}
.d_flex {
  display: flex;
  justify-content: space-between;
}
.c_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.f_flex {
  display: flex;
}
a {
  text-decoration: none !important;
  color: black;
}
li {
  list-style: none;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.RText {
  text-align-last: right;
}
input,
button {
  border: none;
  outline: none;
  background-color: none;
}
.row {
  width: 50%;
}

.icon-circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f3f5f9;
  margin-left: 20px;
  text-align: center;
  border-radius: 50%;
}
.boxShadow {
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
}
.img img{
  width: 100%;
}

.product{
  background: #fff;
  padding: 10px;
  position: relative;
  box-shadow: rgb(3 0 71 / 9%) 0 1px 3px;
  margin: 10px;
}
.product .product-details{
  width: 100%;
  justify-content: center;
  text-align: center;
}
.product-details h3{
  padding: 8px;
  font-weight: 400;
  font-size: 20px;
}
.product-details p{
  padding: 10px;
  font-size: 14px;
}

.product button{
  background: none;
  color: #e94560;
  font-size: 20px;
  border: 1px solid rgb(3 0 71 / 9%);
  width: 200px;
  height: 40px;
  border-radius: 5px;
  transition: 0.5s;
  margin: 20px;
  margin-left: 40px;
}
.product button:hover{
  cursor: pointer;
  background: #e94560;
  color: #fff;
}


.flash{
  background: url(../public/images/bg-1.jpg);
}
.rgba9{
  width: 100%;
  height: 100%;
  padding: 50px 0;
  background: rgba(0, 0, 0, 0.5);
}

.btn-primary {
  background: #e94560;
  padding: 13px 40px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
}
.top {
  margin-top: 80px;
}
.mtop {
  margin-top: 40px;
}
.heading i {
  color: #e94560;
  margin: 10px;
}
/*---------product-----------*/
.product {
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 5px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}
.product h3 {
  font-weight: 400;
  font-size: 17px;
}
.product .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}
.product .price {
  display: flex;
  justify-content: space-between;
  color: #e94560;
}
.price button {
  background: none;
  color: #e94560;
  font-size: 20px;
  transition: 0.5s;
  border: 1px solid rgb(3 0 71 / 9%);
  width: 35px;
  height: 35px;
  border-radius: 5px;
}
.price button:hover {
  cursor: pointer;
  background-color: #e94560;
  color: #fff;
}
.product-like {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  opacity: 0;
  transition: 0.5s;
}
.product-like label {
  background-color: #0f3460;
  padding: 1px 10px;
  font-size: 12px;
  color: white;
  border-radius: 50px;
}
.product-like i {
  font-size: 20px;
  margin: 10px 3px;
}
.product:hover .product-like {
  opacity: 1;
}
/*---------product-----------*/
/*----- control btn -------*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 60px;
  background: #0f3460;
  border-radius: 50%;
  transform: 0.5s;
  z-index: 5;
}
.control-btn .prev {
  left: -20px;
}
.control-btn .next {
  right: -20px;
}
.control-btn .prev i,
.control-btn .next i {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}
.control-btn .prev:hover,
.control-btn .next:hover {
  background: #e94560;
  color: white;
  cursor: pointer;
}
/*----- control btn -------*/
/*----- heading -------*/
.heading .heading-left i {
  font-size: 30px;
}
.heading .heading-left h2 {
  font-size: 25px;
  margin: 5px;
}
.heading .heading-right i,
.heading .heading-right {
  text-align: right;
  color: grey;
}
/*----- heading -------*/
.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
}
.grid2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.boxcon{
  width: 100%;
  /* height: 100vh; */
  background: url(./img/img-bg.png);
  background-size: cover;
  background-attachment: fixed;
  object-fit: cover;
  opacity: 0.8;
}